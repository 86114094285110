import type React from 'react';
import { UIColorStates } from '../types/UI';
import { useNotificationStore } from './store';
import type { NotificationItem } from './types';

export function notify(content: React.ReactNode, options?: NotificationItem) {
  return useNotificationStore.getState().add({ children: content as string, ...options });
}

export function notifyInfo(content: React.ReactNode, options?: NotificationItem) {
  notify(content, {
    ...options,
    color: UIColorStates.info,
  });
}

export function notifySuccess(content: React.ReactNode, options?: NotificationItem) {
  notify(content, {
    ...options,
    color: UIColorStates.success,
  });
}

export function notifyWarning(content: React.ReactNode, options?: NotificationItem) {
  notify(content, {
    ...options,
    color: UIColorStates.warning,
  });
}

export function notifyError(content: React.ReactNode, options?: NotificationItem) {
  notify(content, {
    ...options,
    color: UIColorStates.error,
  });
}
