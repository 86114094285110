import type { DialogChildrenType, DialogProps, DialogRenderProps, DialogResponseType } from '../Dialog/Dialog';
import type { DialogType } from './Dialogs';
import { useDialogStore } from './store';

export interface DialogItem {
  id?: string; // used internally to keep track of dialogs
  type?: DialogType;
  title?: DialogChildrenType;
  scrollable?: boolean;
  closeButton?: DialogChildrenType;
  footer?: DialogChildrenType;
  key?: string;
  content: DialogChildrenType;
  open?: boolean;
  closable?: boolean;
  className?: string;
  style?: React.CSSProperties;

  modalProps?: Partial<DialogProps>;

  cancelButtonProps?: object;
  acceptButtonProps?: object;

  closeOnOverlayClick?: boolean;
  closeOnEscape?: boolean;
  useBlur?: boolean;
  useBackdrop?: boolean;

  // these properties are used internally
  // onClose?(closeResponse: unknown): boolean;
  onClose?(reason?: unknown): boolean;
  onAfterClose?(): void;
  onReady?(renderProps: DialogRenderProps): void;
  onPromiseResolve?(response?: DialogResponseType): void;
  onPromiseReject?(): void;
  response?: DialogResponseType;
}

export function UIAlert(content: DialogChildrenType, options?: Omit<DialogItem, 'content'>) {
  return createDialog({ content, type: 'alert', closeOnOverlayClick: false, ...options });
}

export function UIConfirm(content: DialogChildrenType, options?: Omit<DialogItem, 'content'>) {
  return createDialog({ content, type: 'confirm', closeOnOverlayClick: false, ...options });
}

export function UIError(content: DialogChildrenType, options?: Omit<DialogItem, 'content'>) {
  return createDialog({ content, type: 'error', closeOnOverlayClick: false, ...options });
}

export function UIWarn(content: DialogChildrenType, options?: Omit<DialogItem, 'content'>) {
  return createDialog({ content, type: 'warn', closeOnOverlayClick: false, ...options });
}

export function UISuccess(content: DialogChildrenType, options?: Omit<DialogItem, 'content'>) {
  return createDialog({ content, type: 'success', closeOnOverlayClick: false, ...options });
}

export function UIDialog(content: DialogChildrenType, options?: Omit<DialogItem, 'content'>) {
  return createDialog({ content, type: 'dialog', ...options });
}

function createDialog(item: DialogItem) {
  return new Promise((resolve, reject) => {
    const itemWithPromise = {
      ...item,
      onPromiseResolve: resolve,
      onPromiseReject: reject,
    };

    const itemId = useDialogStore.getState().add(itemWithPromise);
    // if (itemId) useDialogStore.getState().open(itemId);
    // NOTE: The timeout is needed here to allow to backdrop to render first before the enter animation is triggered.
    // TODO: changing the Backdrop from a transition to an animation would fix this issue
    if (!itemId) return;
    setTimeout(() => {
      useDialogStore.getState().open(itemId);
    }, 1);
  });
}

export function UICloseDialog(id: string, response?: DialogResponseType) {
  useDialogStore.getState().close(id, response);
}

export function UICloseAllDialogs(response?: DialogResponseType) {
  useDialogStore.getState().closeAll(response);
}
