'use client';

import Loader from 'react-spinners/ClipLoader';
import type { LoaderSizeProps } from 'react-spinners/helpers/props';

export type SpinnerProps = LoaderSizeProps;

export const Spinner = ({ size = 64, color = 'grey', loading = true, ...otherProps }: SpinnerProps) => {
  return (
    <Loader
      size={size}
      color={color}
      loading={loading}
      aria-label="Loading Spinner"
      data-testid="loader"
      {...otherProps}
    />
  );
};
