import type { StoreApi } from 'zustand';
import { create } from 'zustand';
import type { NotificationItem } from './types';

export interface NotificationContext {
  items: NotificationItem[];
  nextId?: number;
  add(item: NotificationItem): void;
  remove(id: string): void;
  removeAll(): void;
}

const NotificationStore = (set: StoreApi<NotificationContext>['setState']) => ({
  items: [],
  nextId: 0,
  add: (item: NotificationItem) =>
    set(state => {
      item.id = item.id || state.nextId?.toString();
      return {
        items: state.items.concat([item]),
        nextId: state.nextId ? state.nextId + 1 : 1,
      };
    }),
  remove: (id: string) =>
    set(state => {
      return {
        items: state.items.filter(entry => entry.id !== id),
      };
    }),
  removeAll: () =>
    set(() => {
      return {
        items: [],
      };
    }),
});

export const useNotificationStore = create<NotificationContext>(NotificationStore);
