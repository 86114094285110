'use client';

import type {
  ArrowOptions,
  AutoPlacementOptions,
  FlipOptions,
  HideOptions,
  InlineOptions,
  OffsetOptions,
  ShiftOptions,
  SizeOptions,
  UseClickProps,
  UseClientPointProps,
  UseFloatingOptions,
  UseFocusProps,
  UseHoverProps,
  UseTransitionStylesProps,
} from '@floating-ui/react';
import {
  FloatingArrow,
  FloatingFocusManager,
  FloatingOverlay,
  autoPlacement,
  autoUpdate,
  flip,
  arrow as floatingArrow,
  offset as floatingOffset,
  size as floatingSize,
  hide,
  inline,
  limitShift,
  shift,
  useClientPoint,
  useDismiss,
  useFloating,
  useClick as useFloatingClick,
  useFocus as useFloatingFocus,
  useHover as useFloatingHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import React from 'react';
import type { PopoverPlacement } from '../types/UI';
import { PopoverVariationPlacement, UIColors, type UIThemableComponent } from '../types/UI';
import { cn } from '../utils/classnames';

export type PopoverFloatingUIPlacement = PopoverPlacement;

export type PopoverFloatingUIRenderProps = {
  close(): void;
  open(): void;
  toggle(): void;
};

export type PopoverFloatingUIProps = UIThemableComponent & {
  trigger: React.ReactNode;
  triggerOnClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  triggerOnMouseEnter?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  open?: boolean;
  hoverable?: boolean;
  overlap?: boolean;
  variant?: string;
  arrow?: boolean;
  backdrop?: boolean;
  options?: UseFloatingOptions;
  portal?: HTMLElement | null;
  placement?: PopoverFloatingUIPlacement;
  shouldCloseOnBackdropClick?: boolean;
  useClick?: boolean;
  useFocus?: boolean;
  useHover?: boolean;
  lockScroll?: boolean;
  useMousePosition?: boolean;
  useFollowMousePosition?: boolean;

  offset?: OffsetOptions;
  shiftOptions?: ShiftOptions;
  flipOptions?: FlipOptions;
  arrowOptions?: ArrowOptions;
  sizeOptions?: SizeOptions;
  autoPlacementOptions?: AutoPlacementOptions;
  hideOptions?: HideOptions;
  inlineOptions?: InlineOptions;

  hoverProps?: UseHoverProps;
  clickProps?: UseClickProps;
  focusProps?: UseFocusProps;
  transitionStylesProps?: UseTransitionStylesProps;

  children?: React.ReactNode;
  render?: (props: PopoverFloatingUIRenderProps) => React.ReactElement;

  onHover?(): void;
  onOpen?(): void;
  onClose?(): void;
};

export function PopoverFloatingUI({
  open,
  className,
  options,
  onOpen,
  onClose,
  render,
  hoverProps,
  clickProps,
  focusProps,
  offset,
  shiftOptions = {
    limiter: limitShift(),
  },
  flipOptions = {
    fallbackAxisSideDirection: 'start',
  },
  arrowOptions,
  sizeOptions,
  autoPlacementOptions,
  hideOptions,
  inlineOptions,
  transitionStylesProps,
  lockScroll = true,
  trigger,
  useHover,
  useClick,
  useFocus,
  arrow,
  backdrop,
  useMousePosition,
  useFollowMousePosition,
  children,
  placement = PopoverVariationPlacement.BottomStart,

  // ----- Themable props defaults
  color = UIColors.default,
  // size = UISizes.md,
  // variant = UIVariants.solid,
  // shape = UIShapes.rounded,
  // colorShadows,
  // gradient,
  // elevation,

  classNames,
}: PopoverFloatingUIProps) {
  const [_isOpen, _setIsOpen] = React.useState<boolean | undefined>(open);

  const arrowRef = React.useRef(null);

  const [clientPoint, setClientPoint] = React.useState<UseClientPointProps>({
    enabled: useFollowMousePosition || false,
  });

  const { refs, floatingStyles, context, x, y } = useFloating({
    open: _isOpen,
    placement,
    strategy: 'absolute',
    onOpenChange: (isOpen: boolean) => (isOpen ? _open() : _close()),
    middleware: [
      floatingOffset(offset),
      flip(flipOptions),
      shift(shiftOptions),
      sizeOptions && floatingSize(sizeOptions),
      autoPlacementOptions && autoPlacement(autoPlacementOptions),
      hideOptions && hide(hideOptions),
      inlineOptions && inline(inlineOptions),
      arrow &&
        floatingArrow({
          element: arrowRef,
          ...arrowOptions,
        }),
    ],
    whileElementsMounted: autoUpdate,
    ...options,
  });

  const click = useFloatingClick(context, { enabled: useClick === true, ...clickProps });
  const hover = useFloatingHover(context, { enabled: useHover === true, ...hoverProps });
  const focus = useFloatingFocus(context, { enabled: useFocus === true, ...focusProps });
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, transitionStylesProps);

  const clientPointProps = useClientPoint(context, clientPoint);

  const interActionProps = [click, dismiss, role, hover, focus, clientPointProps];

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions(interActionProps);

  const _close = React.useCallback(() => {
    _setIsOpen(false);
    onClose?.();
  }, [onClose]);

  const _open = React.useCallback(async () => {
    setTimeout(() => {
      _setIsOpen(true);
      onOpen?.();
    });
  }, [onOpen]);

  const _toggle = React.useCallback(() => {
    _isOpen ? _open() : _close();
  }, [_close, _isOpen, _open]);

  const onContentClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const renderProps = {
    close: _close,
    open: _open,
    toggle: _toggle,
  };

  React.useEffect(() => {
    if (open === undefined) return;
    open ? _open() : _close();
  }, [_close, _open, open]);

  function roundByDPR(value: number) {
    const dpr = window.devicePixelRatio || 1;
    return Math.round(value * dpr) / dpr;
  }

  if (isMounted) {
    Object.assign(floatingStyles, {
      top: '0',
      left: '0',
      transform: `translate(${roundByDPR(x)}px,${roundByDPR(y)}px)`,
    });
  }

  const popoverContent = () => {
    if (!isMounted) return;
    return (
      <FloatingFocusManager context={context} modal={false}>
        <div
          ref={refs.setFloating}
          style={{ ...floatingStyles, ...transitionStyles }}
          {...getFloatingProps()}
          className={cn('ui-popover z-20 shadow-md', className)}
          onClick={onContentClick}
        >
          {arrow && (
            <FloatingArrow
              ref={arrowRef}
              context={context}
              className={cn(`ui-popover__arrow fill-${color}`, classNames?.arrow)}
            />
          )}
          <div className={cn('ui-popover__content  relative', null, classNames?.content)}>
            {render && typeof render === 'function' ? render(renderProps) : <>{children}</>}
          </div>
        </div>
      </FloatingFocusManager>
    );
  };

  function renderPopoverWithBackdrop() {
    return (
      <FloatingOverlay lockScroll={lockScroll} className={cn('ui-popover__backdrop ', classNames?.backdrop)}>
        {popoverContent()}
      </FloatingOverlay>
    );
  }

  return (
    <div className={cn('ui-popover__wrapper inline-flex', ['cursor-pointer', classNames?.wrapper])}>
      <div
        ref={refs.setReference}
        {...getReferenceProps({
          onClick: (event: React.MouseEvent<HTMLDivElement>) => {
            if (useMousePosition) {
              setClientPoint({ x: event.nativeEvent.clientX, y: event.nativeEvent.clientY });
            }
            _toggle();
          },
        })}
        className={cn('ui-popover__trigger', 'inline-flex', classNames?.trigger)}
      >
        {trigger}
      </div>

      {_isOpen && (backdrop ? renderPopoverWithBackdrop() : popoverContent())}
    </div>
  );
}
