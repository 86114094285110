import {
  UIAlert,
  UICloseAllDialogs,
  UICloseDialog,
  UIConfirm,
  UIDialog,
  UIError,
  UISuccess,
  UIWarn,
} from './Dialogs/api';
import { notify, notifyError, notifyInfo, notifySuccess, notifyWarning } from './Notifications/api';

export const UI = {
  dialogs: {
    alert: UIAlert,
    confirm: UIConfirm,
    error: UIError,
    warn: UIWarn,
    success: UISuccess,
    dialog: UIDialog,
    close: UICloseDialog,
    closeAll: UICloseAllDialogs,
  },
  notifications: {
    notify,
    info: notifyInfo,
    error: notifyError,
    warning: notifyWarning,
    success: notifySuccess,
  },
};
