'use client';

import { cn } from '@wolfejs/ui/utils/classnames';
import React from 'react';
import { Spinner } from '../Spinner/Spinner';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
import type { MouseEventProps, UIComponentProps, UIThemableComponent } from '../types/UI';
import { UIColors, UIShapes, UIShapesExtra, UISizes, UIVariants, UIVariantsExtra } from '../types/UI';

export type AnchorProps = {
  href?: string;
  target?: string;
  rel?: string;
};

export type ButtonProps = UIThemableComponent<UIComponentProps<HTMLButtonElement>, HTMLButtonElement> &
  MouseEventProps<HTMLButtonElement> &
  AnchorProps & {
    /**
     * A reference to the internal html element
     */
    ref?: React.Ref<HTMLButtonElement>;

    /**
     * Display as full width
     */
    fullWidth?: boolean;

    /**
     * Display a wider version
     */
    wide?: boolean;

    /**
     * Display in an active state
     */
    active?: boolean;

    /**
     * Display in an loading state
     */
    loading?: boolean;

    /**
     * Sizes the button as a square
     */
    square?: boolean;

    /**
     * Display in an disabled state
     */
    disabled?: boolean;

    /**
     * Type of button
     */
    type?: 'submit' | 'reset' | 'button' | undefined;

    /**
     * tabIndex
     */
    tabIndex?: number;
  };

const ButtonComp = (
  {
    cname = 'ui-button',
    children,
    className,
    classNames,

    // ----- Themable props defaults
    color = UIColors.default,
    size = UISizes.md,
    variant = UIVariants.solid,
    shape = UIShapes.rounded,
    colorShadows,
    gradient,
    elevation,

    // ----- Options
    tabIndex = 1,
    // href, // TODO
    fullWidth = false,
    wide = false,
    square = false,
    type = 'button',

    // --- States
    disabled,
    active = false,
    loading,

    ...otherProps
  }: ButtonProps,
  forwardedRef: React.ForwardedRef<HTMLButtonElement>
) => {
  const { theme } = useTheme();

  const isSquared: boolean = shape === UIShapesExtra.circle || square;

  // remove component from tabindex when it is disabled
  const _tabIndex = React.useMemo(() => {
    if (disabled) return -1;
    return tabIndex;
  }, [disabled, tabIndex]);

  const classes = React.useMemo(() => {
    const themableProps = { variant, color, shape, size, theme, gradient, elevation, disabled };
    let classes = cn(
      cname,
      // ----- Add standard set of styling classes
      UIStyling.variants(themableProps),
      UIStyling.variantsHover(themableProps),
      UIStyling.elevations(themableProps),
      colorShadows && UIStyling.colorShadows(themableProps),
      UIStyling.shapes(themableProps),
      UIStyling.textSizes(themableProps),
      UIStyling.focusVisibleRing(themableProps),
      isSquared && UIStyling.squareSizes(themableProps),
      !isSquared && UIStyling.buttonPaddings(themableProps),

      'relative cursor-pointer whitespace-nowrap transition',
      `inline-flex items-center justify-center gap-1`,

      theme === 'flowbite' && {
        [`text-${color}-700`]: variant === UIVariants.outline,
        [`text-${color}-900 dark:text-white`]: variant === UIVariants.outline && color === UIColors.default,
        [`text-${color}-400`]: variant === UIVariants.outline && color === UIColors.warning,
        [`hover:text-default-800 dark:hover:text-default-200`]: variant === UIVariants.ghost,
        [`hover:bg-${color} hover:text-default-800 dark:hover:text-default-200`]: variant === UIVariants.outline,
      },
      {
        [`bg-${color}-active`]: active && variant !== UIVariantsExtra.link,

        // non squared paddings
        ['p-1']: isSquared && variant !== UIVariantsExtra.link,
        // no padding for xs icon buttons
        [`p-[1px]`]: size === UISizes.xs && isSquared && variant !== UIVariantsExtra.link,

        [`h-auto border-0 p-0`]: variant === UIVariantsExtra.link,
        [`text-${color}-active`]: active && variant === UIVariantsExtra.link,

        // control svg icon sizing for squared buttons (they can just take up full size since will be no text in this mode)
        ['[&>div>svg]:h-full [&>div>svg]:w-full']: isSquared,

        // control svg icon sizing for non squared buttons
        ['[&>div>svg]:h-3 [&>div>svg]:w-3']: size == UISizes.xs && !isSquared,
        ['[&>div>svg]:h-4 [&>div>svg]:w-4']: size == UISizes.sm && !isSquared,
        ['[&>div>svg]:h-8 [&>div>svg]:w-8']: size == UISizes.md && !isSquared,
        ['[&>div>svg]:h-12 [&>div>svg]:w-12']: size == UISizes.lg && !isSquared,
        ['[&>div>svg]:h-16 [&>div>svg]:w-16']: size == UISizes.xl && !isSquared,

        // w-full is needed to make the button html tag work full width / display: block
        ['flex w-full']: fullWidth,
        ['w-[200px]']: wide,
      },

      // disable pointer cursor
      { ['pointer-events-none']: active },
      { ['cursor-not-allowed']: disabled },
      'disabled:cursor-not-allowed disabled:opacity-80',
      className
    );

    if (variant === UIVariants.unstyled) classes = cn(cname, className);
    return classes;
  }, [
    active,
    className,
    cname,
    color,
    colorShadows,
    disabled,
    elevation,
    fullWidth,
    gradient,
    isSquared,
    shape,
    size,
    theme,
    variant,
    wide,
  ]);

  if (!theme) return null;

  return (
    <button
      {...otherProps}
      ref={forwardedRef}
      tabIndex={_tabIndex}
      disabled={disabled}
      // focusableWhenDisabled={!disabled}
      // href={href}
      type={type}
      // slotProps={{
      //   root(ownerState) {
      //     ownerState.active = active;
      //   },
      // }}
      className={classes}
    >
      {loading && <Spinner color={color} size={24} className="absolute" />}
      <div className={cn('flex items-center', { 'opacity-50': loading }, classNames?.content)}>{children}</div>
    </button>
  );
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ButtonComp
) as React.ForwardRefExoticComponentExtended<ButtonProps>;

export const ButtonVariants = [...Object.values(UIVariants), ...Object.values(UIVariantsExtra)];
export const ButtonShapes = [...Object.values(UIShapes), ...Object.values(UIShapesExtra)];
