import type { StoreApi } from 'zustand';
import { create } from 'zustand';
import type { DialogResponseType } from '../Dialog/Dialog';
import type { DialogItem } from './api';

export interface DialogContext {
  readonly items: DialogItem[];
  readonly nextId?: number;
  add(item: DialogItem): string | undefined;
  open(id: string): void;
  close(id?: string, response?: DialogResponseType): void;
  closeByKey(key?: string, response?: DialogResponseType): void;
  closeAll(response?: DialogResponseType): void;
  remove(id?: string): void;
  removeAll(): void;
}

const DialogStore = (set: StoreApi<DialogContext>['setState']) => ({
  items: [],
  nextId: 0,
  add: (item: DialogItem) => {
    set(state => {
      item.id = item.id || state.nextId?.toString();
      return {
        items: state.items.concat([item]),
        nextId: state.nextId ? state.nextId + 1 : 1,
      };
    });
    return item.id;
  },
  open: (id: string) =>
    set(state => ({
      items: state.items.map(entry => {
        if (entry.id === id) {
          entry.open = true;
        }
        return entry;
      }),
    })),
  close: (id?: string, response?: DialogResponseType) =>
    set(state => ({
      items: state.items.map(entry => {
        // close the last dialog that was opened
        if (!id) id = state.items[state.items.length - 1].id;

        if (entry.open && entry.id === id) {
          // only set the response on the item if one was provided
          // this is to make sure we don't overwrite the response which might have been assigned elsewhere
          if (response !== undefined) entry.response = response;
          entry.open = false;
        }
        return entry;
      }),
    })),
  closeByKey: (key?: string, response?: DialogResponseType) =>
    set(state => ({
      items: state.items.map(entry => {
        if (entry.open && entry.key === key) {
          // only set the response on the item if one was provided
          // this is to make sure we don't overwrite the response which might have been assigned elsewhere
          if (response !== undefined) entry.response = response;
          entry.open = false;
        }
        return entry;
      }),
    })),
  closeAllAboveFirst: () =>
    set(state => ({
      items: state.items.map(entry => {
        entry.open = false;
        return entry;
      }),
    })),
  closeAll: () =>
    set(state => ({
      items: state.items.map(entry => {
        entry.open = false;
        return entry;
      }),
    })),
  remove: (id?: string) =>
    set(state => {
      // remove the last dialog that was opened
      if (!id) id = state.items[state.items.length - 1].id;

      return {
        items: state.items.filter(entry => entry.id !== id),
      };
    }),
  removeAll: () =>
    set(() => ({
      items: [],
    })),
});

export const useDialogStore = create<DialogContext>(DialogStore);
